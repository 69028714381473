import { lazy } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

// 동적 import를 사용하여 컴포넌트 로드
const Landing = lazy(() => import("pages/Landing"));
const NotFoundPage = lazy(() => import("pages/NotFound"));

export const BaseRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
